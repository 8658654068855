/**
 * Этот миксин наследуется всеми полями поиска адреса и ресторанов
 */
import {loadYmap} from 'vue-yandex-maps'
import qs from 'qs';
import {mapGetters, mapMutations} from "vuex";
import {useVkRetargeting} from "@/composables";
import {notification} from "@/utils";

const {vkRetargetingFindLocation} = useVkRetargeting();

export default {
	/**
	 * данные которые наследуются всеми компонентами миксина
	 */
	data()
	{
		return {
			address: '',
			defaultAddress: '',
			suggestItems: [],
			// эта переменная отвечает только за обводку поиска
			// при true появляется обводка
			focused: false,
			error: false,
			restaurants: [],
		}
	},
	computed:
		{
			...mapGetters({
				deliveryAddress: 'map/getDeliveryAddress',
				currentSelectedCity: "getCurrentCity",
				notificationType: 'getMapPopupNotificationType',
				mapAfterInit: 'map/mapAfterInit'
			}),
			hasInputError()
			{
				return (this.deliveryAddress === this.defaultAddress && !this.focused);
			},

			/**
			 * Возвращает список саджестов (адресов)
			 */
			returnCutSuggest()
			{
				return this.suggestItems
			},
		},

	methods:
		{
			...mapMutations({
				setDeliveryAddress: 'map/setDeliveryAddress',
			}),
			/**
			 * выставляет значения из стора
			 * Устанавливаются все значения по которым идет поиск блюд
			 */
			async initialActions()
			{
				// определение выбранной даты и ее установка из кук
				let cookDate = this.$cookie.get('day');
				var tomorowDate = new Date();
				tomorowDate.setDate(tomorowDate.getDate() + 1);
				if (cookDate == tomorowDate.toLocaleDateString('ru'))
					this.$store.dispatch('setDate', 'tomorrow');
				else
					this.$store.dispatch('setDate', 'today');


				document.addEventListener('keydown', this.selectSuggestByKey);

				await this.initRestaurants();
				await loadYmap({
					apiKey: process.env.VUE_APP_YA_API_KEY,
					lang: 'ru_RU',
					coordorder: 'latlong',
					version: '2.1'
				});
			},

			async initRestaurants()
			{
				let restaurantId = this.$cookie.get('restid');
				let coords = this.$cookie.get('coords');
				if (this.deliveryAddress && restaurantId && coords)
				{
					coords = JSON.parse(coords);
					const restaurants = await this.fetchRestaurants(this.$store.state.currentCityId, coords.lat, coords.lan);
					if (!restaurants.length)
					{
						this.$store.dispatch('map/deliveryAddressAction', '');
						this.$cookie.delete('address');
						this.$cookie.delete('coords');
						this.$cookie.delete('restid');
					} else
					{
						this.$store.commit('setRestarauntId', restaurantId);
						this.$store.commit('setCoords', coords);
						this.$store.commit('setAddress', this.deliveryAddress);
					}
					this.$store.commit('setRestaraunts', restaurants);
				}
			},

			setAddressIfCookieExist()
			{
				if (this.$cookie.get('address'))
				{
					this.$store.dispatch('map/deliveryAddressAction', this.$cookie.get('address'));
				}
			},

			/**
			 * Срабатывает по изменению поля адреса
			 * работает с value а не v-model из-за особенностей саггеста котрый смотрит значение из value
			 */
			async changeAddress(event)
			{
				vkRetargetingFindLocation();

				this.$store.dispatch('map/deliveryAddressAction', event.target.value);
				this.suggestItems = await this.getSuggest() || [];

				if (this.notificationType)
				{
					this.$store.commit('setMapPopupNotificationType', false);
				}
			},

			/**
			 * Возвращает подсказки для выбора адреса
			 */
			async getSuggest()
			{
				if (!this.deliveryAddress) return [];

				const currentCity = this.currentSelectedCity ? this.currentSelectedCity.trim() : '';
				const city = currentCity === 'Московская область' ? '' : currentCity;

				const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";

				const options = {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Accept": "application/json",
						"Authorization": `Token ${process.env.VUE_APP_DADATA_API_KEY}`
					},
					body: JSON.stringify({
						query: this.deliveryAddress,
						locations: [{
							city,
						}],
					})
				};

				try
				{
					const response = await fetch(url, options);

					if (!response.ok) return [];

					const data = await response.json();
					return data.suggestions.map(suggestion =>
					{
						return {value: suggestion.value}
					});
				} catch (error)
				{
					console.error('Ошибка при получении подсказок от DaData:', error);
				}

				// Версия с использованием геокодирования от яндекса

				// if (!ymaps || !this.deliveryAddress) return [];
				//
				// try {
				// 	const currentCity = this.currentSelectedCity ? this.currentSelectedCity.trim() : '';
				// 	const city = currentCity === 'Московская область' ? '' : currentCity;
				//
				// 	const query = `Россия, ${city}, ${this.deliveryAddress}`;
				// 	const res = await ymaps.geocode(query, { results: 15 });
				//
				// 	const geoObjects = res.geoObjects.toArray();
				// 	const addresses = geoObjects.map((geoObject) => ({
				// 		address: geoObject.getAddressLine(),
				// 		coordinates: geoObject.geometry.getCoordinates(),
				// 	}));
				//
				// 	return addresses.filter(
				// 		(item) => !item.address.includes('подъезд')
				// 	).map(item => {
				// 		return {value: item.address}
				// 	});
				// } catch (error) {
				// 	console.error("Ошибка при получении подсказок от Яндекс:", error);
				// 	return [];
				// }
			},
			/**
			 * Возвращает координаты или вылетает с ошибкой
			 */
			async getAddressCoorinates(address = this.deliveryAddress, exact = false)
			{
				if (!ymaps) return false;

				const yRes = await ymaps.geocode(address);
				const yGObject = yRes.geoObjects.get(0);

				if (!yGObject) return notification({title: 'Адрес не найден!', type: 'error'});

				const yPropsCode = yGObject.properties.get('metaDataProperty.GeocoderMetaData.precision');

				if (!yPropsCode) return notification({title: 'Адрес не найден!', type: 'error'});

				if (!exact && yPropsCode !== 'exact') return false;

				return {
					'lat': yGObject.geometry._coordinates[0],
					'lan': yGObject.geometry._coordinates[1]
				};
			},

			/**
			 * Достает рестораны по координатам
			 */
			async fetchRestaurants(cityCode, lat, long)
			{
				try
				{
					const day = this.$store.state.currentDate || '';
					const params = qs.stringify({'day': day, 'city_name': cityCode, 'lat': lat, 'long': long});
					const {data} = await this.$axios.post('/address/restaurants/', params);

					if (!data.success) return [];

					return data.restaurants;
				} catch (e)
				{
					throw new Error(e);
				}
			},

			/**
			 * записывает все рестораны, возвращает в компонент один из возможных вариантов:
			 * один ресторан, несколько, нет ресторанов, false - ошибка
			 */
			async setRestaurants({address, checkProof, incommingRestaurants} = {
				address: this.deliveryAddress,
				checkProof: false,
				incommingRestaurants: []
			})
			{
				try
				{
					this.coordinates = await this.getAddressCoorinates(address, checkProof);

					// Если уже есть рестораны, полученные ранее, то просто заполняем все данные вместе с куками
					if (incommingRestaurants.length)
					{
						this.$store.commit('setRestarauntId', incommingRestaurants[0].id);
						if (this.coordinates)
							this.$store.commit('setCoords', {lat: this.coordinates.lat, lan: this.coordinates.lan});
						this.$store.commit('setAddress', this.deliveryAddress);
						return 'oneRest';
					}

					this.restaurants = await this.fetchRestaurants(this.$store.state.currentCityId, this.coordinates.lat, this.coordinates.lan);

					if (!this.restaurants.length)
					{
						this.showNoRestaurantsPopup = true;
						return 'noRest';
					} else
						this.$store.commit('setRestaraunts', this.restaurants);

					if (this.restaurants.length === 1)
					{
						this.$store.commit('setRestarauntId', this.restaurants[0].id);

						if (this.coordinates) this.$store.commit('setCoords', {
							lat: this.coordinates.lat,
							lan: this.coordinates.lan
						});

						this.$store.commit('setAddress', this.deliveryAddress);

						return 'oneRest';
					}

					if (this.restaurants.length > 1)
					{
						if (this.coordinates) this.$store.commit('setCoords', {
							lat: this.coordinates.lat,
							lan: this.coordinates.lan
						});

						this.$store.commit('setAddress', this.deliveryAddress);

						return 'severalRest';
					}
				} catch (e)
				{
					return false;
				}
			},
			/**
			 * Отрабатывает когда в попапе выбрали ресторан
			 * Сохраняет его в сторе и и перекидывает в меню
			 */
			async selectRestaurant(id)
			{
				this.$store.commit('setRestarauntId', id);
				await this.$store.dispatch('getMenu');
				await this.$store.dispatch('getCart');
				if (this.coordinates)
					this.$store.commit('setCoords', {lat: this.coordinates.lat, lan: this.coordinates.lan});
				this.$store.commit('setAddress', this.deliveryAddress);
			},
			/**
			 * Центрирует карту по координатам
			 */
			async centerMapCoordinates(address, coords)
			{
				if (!this.mapAfterInit) return;

				if (coords)
				{
					const [lat, lan] = coords;

					return this.mapAfterInit.setCenter([lat, lan], 18, {duration: 500});
				}

				const {lat, lan} = await this.getAddressCoorinates(address, false);

				if (!lat || !lan) return;

				this.mapAfterInit.setCenter([lat, lan], 18, {duration: 500});
			},

			/**
			 * убирает активность со всех элементов
			 */
			clearAllSuggestActive()
			{
				for (let suggest of this.suggestItems)
					suggest.active = false;
			},

			/**
			 * навигация стрелками с клавиатуры
			 * выбор активного элемента
			 */
			selectSuggestByKey(e)
			{
				// не выполняется при скрытой подсказке
				if (!this.returnCutSuggest.length)
				{
					this.activeSuggestion = -1;
					return
				}

				// не выполняется, если нажата была не стрелка
				if (e.keyCode != 38 && e.keyCode != 40) return

				let activeIndex = -1;

				// поиск индекса старого активного элемента
				// по умолчанию -1
				for (let ind in this.suggestItems)
					if (this.suggestItems[ind].active)
						activeIndex = ind;

				this.clearAllSuggestActive();

				// поиск индекса текущего активного эелемента,
				// чтобы не вышел за пределы массива

				// стрелка вверх
				if (e.keyCode == 38)
					activeIndex == 0
						? activeIndex = this.suggestItems.length - 1
						: --activeIndex;

				// стрелка вниз
				else
					activeIndex == this.suggestItems.length - 1
						? activeIndex = 0
						: ++activeIndex;

				this.suggestItems[activeIndex].active = true;
				this.deliveryAddress = this.suggestItems[activeIndex].displayName;
			},

			async placeholderHideOnFocusSearchInput()
			{
				// Если поле ввода адреса пустое и выбран город, то подставляем в поле ввода название нашего города
				if (this.deliveryAddress.trim() === '' && this.currentSelectedCity)
				{
					this.$store.dispatch('map/deliveryAddressAction', `${this.currentSelectedCity}, `);
				}

				if (this.deliveryAddress === this.defaultAddress)
				{
					this.$store.dispatch('map/deliveryAddressAction', '');
				}

				if (this.deliveryAddress.trim() !== '') this.suggestItems = await this.getSuggest() || [];

				this.focused = true;
			},

			placeholderShowOnBlurSearchInput()
			{

				if (window.innerWidth > 567)
					setTimeout(() => this.suggestItems = false, 500);

				if (this.deliveryAddress === '')
					this.$store.dispatch('map/deliveryAddressAction', this.defaultAddress)

				this.focused = false;
			},


			/**
			 * Загрузка зон с бэкенда - требуется для определения попадания выбранной подсказки
			 * в зону доставки, чтобы сразу перебросить пользователя на экран меню,
			 * если адрес входит в зону доставки
			 */
			async getZones()
			{
				if (!this.$store.state.map.fullZones)
				{
					await this.$store.dispatch('map/getZones');
				}
			},

			/**
			 * Проверка выбранной подсказки - если она входит в зону доставок,
			 * пользователя необохдимо перекинуть на выбор меню, иначе -
			 * открыть попап карту с зонами
			 * @param {any} onlyClosePopup - Необязательный параметр, который говорит нам о том, нужно ли
			 * открывать попап карту или нет, т.к. на страницах Создание\Настройка корзины
			 * и География покрытия (CoveragePage.vue) попап карта не нужна.
			 */
			async checkSelectedSuggestion(suggestion, onlyClosePopup)
			{
				if (!suggestion.value) return;

				this.setDeliveryAddress(suggestion.value);

				let geo = (await ymaps.geocode(suggestion.value)).geoObjects.get(0);

				let coords = geo && [geo.geometry._coordinates[0], geo.geometry._coordinates[1]];

				if (geo && !!this.$store.state.map.fullZones.length)
				{
					let containsInZone = null;

					for (let i = 0; i < this.$store.state.map.fullZones.length; i++)
					{
						if (this.raycastPointToZone(coords, this.$store.state.map.fullZones[i].poligon))
						{
							containsInZone = this.$store.state.map.fullZones[i];
						}
					}

					if (!containsInZone && !onlyClosePopup)
					{
						await this.$store.dispatch('openPopup', 'AddressMapPopup');
					}

					await this.$store.dispatch("map/setCurrentAddressPrecision", geo.properties.get("metaDataProperty.GeocoderMetaData.precision"));
				} else if (!onlyClosePopup)
				{
					await this.$store.dispatch('openPopup', 'AddressMapPopup');
				}

				if (this.suggestItems.length === 1) await this.$store.dispatch('openPopup', 'AddressMapPopup');

				await this.centerMapCoordinates(suggestion.value, coords);
			},

			/**
			 * Алгоритм проверки нахождения точки внутри полигона
			 */
			raycastPointToZone(point, vs)
			{
				let x = point[0], y = point[1];

				let inside = false;
				for (let i = 0, j = vs.length - 1; i < vs.length; j = i++)
				{
					let xi = vs[i][0], yi = vs[i][1];
					let xj = vs[j][0], yj = vs[j][1];

					let intersect = ((yi > y) != (yj > y))
						&& (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
					if (intersect) inside = !inside;
				}

				return inside;
			},
		},

}
